.navbar-toggler {
    border: #fefefe 1px solid !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28254, 254, 254, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

#logo {
    height: 70px;
    width: auto;
}

.btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%270%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat !important;
}

.background {
    background-color: #35bcf293;
    color: #fefefe !important;
    border-radius: 10px;
    position: absolute;
}

.offcanvas-header, .offcanvas-body {
    background-color: #E0A942;
}

.offcanvas-header {
    border-bottom: 1px solid #fefefe;
}

.nav-link {
    color: #fefefe !important; 
    font-size: 20px !important;
    text-shadow: 1px 1px #8c8c8c !important;
    display: flex;
    align-items: center;
}

.menuBackground {
    background-image: linear-gradient(to right, rgba(53,189,242,0), rgba(53,189,242,1));
}

.white {
    color: white;
}

.navbar {
    background-color: transparent;
    z-index: 2;
}

.teste {
    background-color: #E0A942;
    transition: .9s linear all;
}

.show {
    height: calc(100vh - 56px);
    margin-top: 10px;
}

.itens {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media screen and (min-width: 768px) {
    .navbar {
        background-color: transparent;
    }
}