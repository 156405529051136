.footer-distributed{
	background: #8c8c8c;
	width: 100%;
	padding: 20px;
}

.style {
	display: grid;
	grid-gap: 32px;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
}

.footer-distributed .footer-left, .footer-center, .footer-right {
	display: inline-block;
	vertical-align: top;
}

.footer-distributed .footer-center span{
	display: inline-block;
	font-size: 14px;
	line-height: 2;
	color: #fefefe;
    font-weight: 400;
	align-items: center;
}

.footer-distributed .footer-center a{
	color:  #fefefe;
	text-decoration: none;
	font-size: 14px;
	font-weight: 400;
	line-height: 2;
}

/* Footer Right */

.footer-distributed .footer-company-about span{
	display: block;
	color:  #ffffff;
	font-size: 14px;
	font-weight: bold;
	line-height: 20px;
	margin-bottom: 10px;
}

.footer-distributed .footer-icons{
	margin-top: 15px;
	display: flex;
	gap: 32px;
	justify-content: center;
	width: 250px;
}

.footer-distributed .footer-icons a{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #fefefe;
	border-radius: 8px;
}

#logoFooter {
    height: 25px;
    width: 25px;
}

#whatsAppIcon {
    color: #25d366;
}

#envelopeIcon {
    color: #35bdf2;
}

#mapIcon {
	color: #f28705;
	height: 20px;
	width: 20px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {
	.style {
		grid-gap: 20px;
		grid-template-columns: 1fr;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		text-align: center;
	}

	.footer-distributed .footer-center{
		display: flex;
        flex-direction: column;
        gap: 10px;
	}

	.footer-distributed .footer-icons{
		margin-top: 0;
		display: flex;
		gap: 32px;
		justify-content: center;
		width: 100%;
	}

}
