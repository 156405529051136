.backgroundImg {
    background-image: url("../../assets/imgs/pexels-cottonbro-studio-9243876.jpg");
    background-size: cover;
    background-position: center center;
    height: 50vh;
    opacity: 0.6;
}

.linkDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fefefe;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    gap: 20px;
}

.linkDiv h3 {
    color: #fefefe;
    text-align: center;
    text-shadow: 1px 1px black;
}

.linkDiv h3 {
    font-size: 25px;
}

#logoMercadoLivre {
    height: 50px;
    text-decoration: underline;
    cursor: pointer;
}

#logoWhatsApp {
    height: 50px;
    text-decoration: underline;
    cursor: pointer;
}

.linkDivIcons {
    display: flex;
    gap: 30px;
}

.backgroundIcon {
    padding: 5px;
    background-color: #fefefe85;
    border-radius: 10px;
}

@media screen and (min-width: 768px) {
    .backgroundImg {
        background-image: url("../../assets/imgs/pexels-cottonbro-studio-9243876-l1.jpg");
    }
}

@media screen and (min-width: 1200px) {
    .linkDiv {
        justify-content: center;
    }
}
