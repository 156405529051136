.cardStyle {
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    width: 200px;
    background-color: #fefefe;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#divImg {
    width: 160px;
    height: 160px;
}

#productImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 5px;

}

.productTitleDiv {
    padding: 5px 10px;
    width: 100%;
}

.productTitle {
    text-overflow: ellipsis;
    text-align: center;
    white-space: wrap;
    overflow: hidden;
    font-size: 14px;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

/*Animated

.animated {
    overflow: hidden;
  
    white-space: nowrap;
}

.animated>* {
    display: inline-block;
    position: relative;
    animation: 3s linear 0s infinite alternate move;
}

.animated>*.min {
    min-width: 100%;
}

@keyframes move {

    0%,
    25% {
        transform: translateX(0%);
        left: 0%;
    }

    75%,
    100% {
        transform: translateX(-100%);
        left: 100%;
    }
}

/* Non-solution styles

.animated>* {
    box-sizing: border-box;
    padding: .1rem 1rem;
}
*/