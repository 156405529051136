.confeitos {
    height: 100%;
    margin-bottom: 10%;
}

.confeitos .electronicList {
    color: #636363;
}

.space {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.candiesList {
    overflow: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-bottom: 5px;
}

.candiesList::-webkit-scrollbar {
    display: none;
}

.confeitosUnderline {
    border-bottom: 5px solid #f28705b4;
    width: fit-content;
}

@media screen and (min-width: 768px) {
    .candiesList::-webkit-scrollbar {
        display: block;
    }

    .candiesList::-webkit-scrollbar {
        height: 0.3em;
    }

    .candiesList::-webkit-scrollbar-track {
        border: 1px solid white;
        background-color: #b2bec3;
    }

    .candiesList::-webkit-scrollbar-thumb {
        background-color: #f28705;
        border-radius: 10px;
    }
}

@media screen and (min-width: 1200px) {
    .confeitos {
        margin-bottom: 5%;
    }
}

