#firstSection {
    height: 100vh;
    background-image: url('../../assets/imgs/pexels-max-fischer-5869606-p.jpg');
    background-size: cover;
    background-position: 80%;
}

.mainPhrase {
    color: #FEFEFE;
    font-weight: bold;
    position: absolute;
    top: 35%;
    text-transform: uppercase;
    font-size: xx-large;
    text-shadow: 1px 1px black;
    z-index: 1;
}

@media screen and (min-width: 768px) {
    .mainPhrase {
        text-align: right;
        font-size: 50px;
        padding-right: 20px;
        
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    
    #firstSection {  
        background-image: url('../../assets/imgs/pexels-max-fischer-5869606-l.jpg'); 
        background-size: 160%;
        background-repeat: no-repeat;
        background-position: top right;
    }
}

@media screen and (min-width: 1200px) {
    .mainPhrase {
        position: relative;
        padding-top: 100px;
        padding-left: 300px;
        text-align: right;
        font-size: 80px;
    }
    
    #firstSection {   
        background-size: 180%;
        background-position: top right;
        background-repeat: no-repeat;
    }
}