.electronics {
    height: 100%;
    margin-bottom: 10%;
}

.space {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.electronicList {
    overflow: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-bottom: 5px;
}

.electronicList::-webkit-scrollbar {
    display: none;
}

.underline {
    border-bottom: 5px solid #35bdf2;
    width: fit-content;
}

@media screen and (min-width: 768px) {
    .electronicList::-webkit-scrollbar {
        display: block;
    }

    .electronicList::-webkit-scrollbar {
        height: 0.3em;
    }

    .electronicList::-webkit-scrollbar-track {
        border: 1px solid white;
        background-color: #b2bec3;
    }

    .electronicList::-webkit-scrollbar-thumb {
        background-color: #35bdf2;
        border-radius: 10px;
    }
}

@media screen and (min-width: 1200px) {
    .electronics {
        margin-bottom: 5%;
    }
}